<template>
  <div class="tags-wrapper">
    <div
      class="scroll-btn-prev"
      @click="scroll('prev')"
      v-show="scrollLeft > 0"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="6"
        height="16"
        viewBox="0 0 6 16"
        fill="none"
      >
        <path
          d="M5.83776 2.04148C6.13682 1.57717 6.00285 0.958342 5.53854 0.659288C5.37077 0.55123 5.18282 0.499708 4.99708 0.499878C4.66876 0.500174 4.34732 0.661969 4.15633 0.958515L0.667321 6.37556C0.665633 6.37818 0.663949 6.3808 0.66227 6.38342L0.318578 6.91704C-0.106193 7.57654 -0.106193 8.42348 0.31858 9.08298L0.662295 9.61663C0.663967 9.61925 0.665643 9.62186 0.667324 9.62447L4.15633 15.0415C4.26847 15.2156 4.4256 15.3433 4.60267 15.4191C4.85237 15.5259 5.14174 15.5297 5.40067 15.4151C5.44778 15.3942 5.49387 15.3695 5.53854 15.3407C6.00285 15.0417 6.13682 14.4228 5.83776 13.9585L2.34874 8.5415C2.34777 8.53998 2.34679 8.53846 2.34583 8.53694C2.13733 8.20937 2.13733 7.79064 2.34583 7.46307L5.83776 2.04148Z"
          fill="#3D75E4"
        />
      </svg>
    </div>
    <ul
      class="tags-scroll"
      ref="slider"
    >
      <li
        v-for="tag in tags"
        :key="tag.id"
        @click="selectTag(tag)"
      >
        <tag
          :title="tag.title"
          :active="tag.active"
        />
      </li>
    </ul>
    <div
      class="scroll-btn-next"
      @click="scroll('next')"
      v-show="isShowNextBtn"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="6"
        height="16"
        viewBox="0 0 6 16"
        fill="none"
      >
        <path
          d="M0.162462 2.04148C-0.136592 1.57717 -0.00262327 0.958342 0.461688 0.659288C0.62946 0.55123 0.817406 0.499708 1.00315 0.499878C1.33146 0.500174 1.6529 0.661969 1.8439 0.958515L5.33291 6.37556C5.33459 6.37818 5.33628 6.3808 5.33796 6.38342L5.68165 6.91704C6.10642 7.57654 6.10642 8.42348 5.68165 9.08298L5.33793 9.61663C5.33626 9.61925 5.33458 9.62186 5.3329 9.62447L1.8439 15.0415C1.73175 15.2156 1.57463 15.3433 1.39755 15.4191C1.14786 15.5259 0.858481 15.5297 0.599553 15.4151C0.55245 15.3942 0.506353 15.3695 0.461687 15.3407C-0.00262423 15.0417 -0.136592 14.4228 0.162463 13.9585L3.65148 8.5415C3.65246 8.53998 3.65343 8.53846 3.6544 8.53694C3.8629 8.20937 3.8629 7.79064 3.65439 7.46307L0.162462 2.04148Z"
          fill="#3D75E4"
        />
      </svg>
    </div>
  </div>
</template>

<script>
import Tag from './TagCarouselItem';

export default {
  components: {
    Tag
  },

  props: ['items'],

  data: () => ({
    scrollLeft: 0,
    offsetWidth: 0,
    scrollWidth: 0,
    tags: []
  }),

  computed: {
    isShowNextBtn() {
      return this.scrollLeft + this.offsetWidth < this.scrollWidth;
    }
  },
  watch: {
    items(val) {
      this.tags = [...val];
    }

    // items: {
    //   deep: true,
    //   handler(val) {
    //     console.log('newVal', val);
    //     this.tags = [...val];
    //   }
    // }
  },

  updated() {
    const carousel = this.$refs.slider;
    this.scrollWidth = carousel.scrollWidth;
    this.offsetWidth = carousel.offsetWidth;
    this.scrollLeft = carousel.scrollLeft;
  },

  mounted() {
    this.tags = [...this.items];
    const carousel = this.$refs.slider;

    carousel.addEventListener('scroll', () => {
      this.scrollLeft = carousel.scrollLeft;
      this.scrollWidth = carousel.scrollWidth;
    });

    const { slider } = this.$refs;
    let isDown = false;
    let startX;
    let scrollLeft;

    slider.addEventListener('mousedown', e => {
      isDown = true;
      slider.classList.add('active');
      startX = e.pageX - slider.offsetLeft;
      scrollLeft = slider.scrollLeft;
    });
    slider.addEventListener('mouseleave', _ => {
      isDown = false;
      slider.classList.remove('active');
    });
    slider.addEventListener('mouseup', _ => {
      isDown = false;
      slider.classList.remove('active');
    });
    slider.addEventListener('mousemove', e => {
      if (!isDown) return;
      e.preventDefault();
      const x = e.pageX - slider.offsetLeft;
      const SCROLL_SPEED = 1;
      const walk = (x - startX) * SCROLL_SPEED;
      slider.scrollLeft = scrollLeft - walk;
    });
  },

  methods: {
    scroll(direction) {
      const carousel = this.$refs.slider;
      // Ширина прокрутки
      const scrollWidth = 200;
      const width = direction === 'next' ? scrollWidth : scrollWidth * -1;
      carousel.scrollTo(carousel.scrollLeft + width, 0);
    },
    selectTag(el) {
      this.tags = this.tags.map(tag => ({
        ...tag,
        active: tag.title === el.title
      }));
      this.$emit('select', { ...el, active: true });
    }
  }
};
</script>
<style scoped>
.tags-wrapper {
  padding: 0;
  margin: 0;
  position: relative;
  --desktopPadding: 40px;
  --tabletPadding: 32px;
  --mobilePadding: 24px;
  max-width: 100%;
}
@media (max-width: 1025px) {
  .tags-wrapper{
    max-width: 100%;
  }
}
.tags-scroll {
  display: flex;
  flex-direction: row;
  column-gap: 8px;
  overflow: scroll;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch; /* Important for iOS devices */
  scroll-snap-type: both mandatory;
  scroll-padding: 1rem;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.tags-scroll::-webkit-scrollbar {
  display: none;
}

.active {
  scroll-snap-type: unset;
}

.tags-scroll > li {
  list-style: none;
}

.tags-scroll > * {
  /* scroll-snap-align: start; */
  /* display: inline-block; */
  /* border-radius: 3px; */
  /* font-size: 0; */
}

.scroll-btn-prev,
.scroll-btn-next {
  background: transparent;
  box-shadow: 0px 0px 0px transparent;
  border: 0px solid transparent;
  text-shadow: 0px 0px 0px transparent;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  position: absolute;
  left: -20px;
  top: 50%;
  transform: translateY(-50%);
  height: 26px;
  cursor: pointer;
  z-index: 2;
  display: flex;
  align-items: center;
}
.scroll-btn-prev:before,
.scroll-btn-next:before {
  content: "";
  width: var(--desktopPadding);
  height: 26px;
  left: 50%;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 1) 40%,
      rgba(255, 255, 255, 0) 100%
  );
  z-index: -1;
  pointer-events: none;
}

.scroll-btn-next {
  left: unset;
  right: -20px;
}
.scroll-btn-next:before {
  background: linear-gradient(
      270deg,
      rgba(255, 255, 255, 1) 40%,
      rgba(255, 255, 255, 0) 100%
  );
  left: unset;
  right: 50%;
}
</style>
