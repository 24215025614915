<template>
  <main
    class="mb-10"
    v-if="loaded"
  >
    <h1 class="cheddar">
      Новости
    </h1>

    <div class="mt-8 mb-8 align-center justify-space-between">
      <!-- snap snap-x snap-mandatory overflow-auto no-scrollbar -->
      <tag-carousel
        :items="categories"
        @select="onChangeType"
      />
      <!--
      <div class="flex flex-wrap gap-2 mb-4">
        <c-tag-btn
          :active="!getParams.category_id"
          @click="
            getParams.category_id = null;
            filterBy();
          "
        >
          Все категории
        </c-tag-btn>
        <c-tag-btn
          v-for="It in categories"
          :key="It.id"
          :active="It.id === getParams.category_id"
          @click="
            getParams.category_id === It.id
              ? (getParams.category_id = null)
              : (getParams.category_id = It.id);
            filterBy();
          "
        >
          {{ It.name }}
        </c-tag-btn>
      </div>
      -->
      <div class="flex-shrink-0 flex lg:mt-4 mmt">
        <div class="opacity-48">
          Показывать
        </div>
        <c-dropdown
          right
          class="ml-3"
        >
          <!-- Button content -->
          <template #button>
            <div class="flex items-center">
              <span class="mr-2">
                {{ periodTimeObj[periodTimeKey] || "За всё время" }}
              </span>
              <svg
                class="w-4 h-4"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <path
                  d="M4.516 7.548c.436-.446 1.043-.481 1.576 0L10 11.295l3.908-3.747c.533-.481 1.141-.446 1.574 0 .436.445.408 1.197 0 1.615-.406.418-4.695 4.502-4.695 4.502a1.095 1.095 0 0 1-1.576 0S4.924 9.581 4.516 9.163c-.409-.418-.436-1.17 0-1.615z"
                />
              </svg>
            </div>
          </template>

          <!-- Opened dropdown content -->
          <template #content="{ close }">
            <nav class="flex flex-col bg-white w-42 rounded box-border">
              <c-list-item
                @click="
                  filterPeriod();
                  close();
                "
                :active="!getParams.created_at_from"
              >
                За все время
              </c-list-item>
              <c-list-item
                v-for="(Val, Key, idx) in periodTimeObj"
                :key="idx"
                @click="
                  filterPeriod(Key);
                  close();
                "
                :active="periodTimeKey === Key"
              >
                {{ Val }}
              </c-list-item>
            </nav>
          </template>
        </c-dropdown>
      </div>
    </div>

    <center
      class="h-screen-sm flex"
      v-if="!list.length"
    >
      <h3 class="taleggio m-auto">
        Ни одна новость не удовлетворяет
        <br>условиям фильтра
      </h3>
    </center>
    <transition-group
      name="fade"
      class="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8"
    >
      <RouterLink
        :to="`/${It.id}`"
        class="group"
        v-for="It in list"
        :key="It.id"
      >
        <figure>
          <picture class="overflow-hidden rounded-16 block aspect-10/16">
            <div
              class="
                object-fill
                min-h-full min-w-full
                bg-no-repeat bg-cover bg-center
                blur-5
                filter
                scale-120
                transform
              "
              :style="{
                backgroundImage: `url(${
                  It.images[0] ||
                  'https://raw.githubusercontent.com/zupra/test/f00bc426b0cb7c28aba1460ee1178d9bbc4f5e9e/img/basic.svg'
                })`,
              }"
            />
            <div
              class="
                object-fill
                min-h-full min-w-full
                bg-no-repeat bg-contain bg-center
                z-1
                absolute
                inset-0
              "
              :style="{
                backgroundImage: `url(${
                  It.images[0] ||
                  'https://raw.githubusercontent.com/zupra/test/f00bc426b0cb7c28aba1460ee1178d9bbc4f5e9e/img/basic.svg'
                })`,
              }"
            />
            <!--            <img-->
            <!--              class="object-cover rounded-16"-->
            <!--              :src="-->
            <!--                It.images[0] ||-->
            <!--                'https://raw.githubusercontent.com/zupra/test/f00bc426b0cb7c28aba1460ee1178d9bbc4f5e9e/img/basic.svg'-->
            <!--              "-->
            <!--              alt-->
            <!--            />-->
          </picture>
          <figcaption>
            <h3
              class="
                line-clamp-3
                taleggio
                mt-4
                mb-2
                group-hover:text-interstellar
              "
              v-html="It.teaser || It.title"
            />
            <div class="parmigiano opacity-48 group-hover:opacity-64">
              {{ $dayjs(It.created_at).format("D MMMM YYYY") }}
            </div>
          </figcaption>
        </figure>
      </RouterLink>
    </transition-group>

    <button
      class="button mt-10 w-full"
      v-if="hasLoadMore"
      @click="loadMore"
    >
      Показать ещё
    </button>

    <!-- <pre class="mt-96">list:{{list}}</pre> -->
  </main>
</template>

<script>
// @ is an alias to /src
import TagBtn from '@/components/TagBtn.vue';
import Dropdown from '@/components/Dropdown.vue';
import ListItem from '@/components/ListItem.vue';
import tagCarousel from '../components/TagCarousel';

const getParams = {
  per_page: 12,
  'sort[created_at]': 'desc',
  start_top: 1,
  'no-cache': 1
};
const API = 'news';

export default {
  name: 'List',
  components: {
    CTagBtn: TagBtn,
    CDropdown: Dropdown,
    CListItem: ListItem,
    tagCarousel
  },

  data() {
    return {
      loaded: false,
      getParams: {
        ...getParams,
        category_id: null,
        created_at_from: null
      },
      list: [],
      categories: [],
      meta: null,
      // https://day.js.org/docs/en/manipulate/start-of
      periodTimeObj: {
        D: 'За день',
        w: 'За неделю',
        M: 'За месяц',
        Q: 'За квартал',
        y: 'За год'
      },
      periodTimeKey: null // 'За всё время',
    };
  },

  computed: {
    hasLoadMore() {
      // return this.list && !(this.meta?.current_page === this.meta?.last_page);
      return (
        this.meta?.last_page > 1
        && !(this.meta?.current_page === this.meta?.last_page)
      );
    },
    nextPage() {
      return this.meta?.current_page + 1;
    }
  },

  created() {
    this.getData();
  },

  methods: {
    onChangeType(type) {
      if (type.id !== this.getParams.category_id) {
        this.getParams.category_id = type.id;
        this.filterBy();
      }
    },
    async getData() {
      try {
        const [newsRes, categoriesRes] = await Promise.all([
          this.$axios.get(API, { params: getParams }),
          this.$axios.get('news/categories')
        ]);
        this.list = newsRes.data.data;
        this.meta = newsRes.data.meta;
        this.categories = categoriesRes.data.data.map(el => ({
          ...el,
          title: el.name,
          active: false
        })).sort(
          (a, b) => a.order - b.order
        );
        this.categories.unshift({ title: 'Все категории', active: true, id: null });
      } catch (error) {
        console.log(error);
      } finally {
        this.loaded = true;
      }
    },
    filterPeriod(Key) {
      if (!Key) {
        this.getParams.created_at_from = null;
        this.periodTimeKey = null; // 'За всё время'
        this.filterBy();
      } else {
        this.getParams.created_at_from = this.$dayjs()
          .startOf(Key)
          .format('YYYY-MM-DD');
        this.periodTimeKey = Key;
        this.filterBy();
      }
    },
    async filterBy() {
      const { data } = await this.$axios.get(API, {
        params: { ...this.getParams }
      });
      this.list = data.data;
      this.meta = data.meta;
    },
    async loadMore() {
      const { data } = await this.$axios.get(API, {
        params: {
          page: this.nextPage,
          ...this.getParams
        }
      });
      this.list.push(...data.data);
      this.meta = data.meta;
    }
  }
};
</script>
<style>
@media(max-width: 1024px){
  .mmt{
    margin-top: 12px;
  }
}
</style>
<style>
.ListItem.active::before {
  border-radius: 0px 3px 3px 0px;
}
</style>
