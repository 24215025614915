<template>
  <div
    class="flex"
    style="margin-top: 80px; margin-bottom: 95px"
  >
    <div class="mr-8">
      <h1 class="camembert mb-6">
        Страница не&nbsp;существует
      </h1>
      <p class="parmigiano opacity-72 mb-2">
        Ошибка 404
      </p>
      <p class="parmigiano opacity-72 mb-8">
        К сожалению, этой страницы нет или она была удалена.<br>
        Зато у нас много других интересных страниц.
      </p>
      <div class="flex">
        <a
          href="/"
          class="text-rocky mr-4 sulguni flex align-center"
        >
          <r-icon
            icon="home"
            fill="rocky"
            class="mr-1"
          />
          На главную
        </a>
        <r-button
          class="text-rocky mr-4 sulguni flex align-center"
          @click="openModal"
          icon="complaint"
          type-button="button"
          size="helike"
          width="wide"
          type="primary"
          color="rocky"
          title="Сообщить об ошибке1"
        />
      </div>
    </div>
    <img
      src="../assets/error-404.png"
      alt="Ошибка 404"
      style="width: auto"
    >
  </div>
</template>

<script>
export default {
  name: 'Page404',
  methods: {
    openModal() {
      window.dispatchEvent(
        new CustomEvent('openModal', {
          detail: {
            name: 'supportModal'
          }
        })
      );
    }
  }
};
</script>

<style scoped></style>
