var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.loaded)?_c('main',{staticClass:"mb-10"},[_c('h1',{staticClass:"cheddar"},[_vm._v(" Новости ")]),_c('div',{staticClass:"mt-8 mb-8 align-center justify-space-between"},[_c('tag-carousel',{attrs:{"items":_vm.categories},on:{"select":_vm.onChangeType}}),_c('div',{staticClass:"flex-shrink-0 flex lg:mt-4 mmt"},[_c('div',{staticClass:"opacity-48"},[_vm._v(" Показывать ")]),_c('c-dropdown',{staticClass:"ml-3",attrs:{"right":""},scopedSlots:_vm._u([{key:"button",fn:function(){return [_c('div',{staticClass:"flex items-center"},[_c('span',{staticClass:"mr-2"},[_vm._v(" "+_vm._s(_vm.periodTimeObj[_vm.periodTimeKey] || "За всё время")+" ")]),_c('svg',{staticClass:"w-4 h-4",attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 20 20"}},[_c('path',{attrs:{"d":"M4.516 7.548c.436-.446 1.043-.481 1.576 0L10 11.295l3.908-3.747c.533-.481 1.141-.446 1.574 0 .436.445.408 1.197 0 1.615-.406.418-4.695 4.502-4.695 4.502a1.095 1.095 0 0 1-1.576 0S4.924 9.581 4.516 9.163c-.409-.418-.436-1.17 0-1.615z"}})])])]},proxy:true},{key:"content",fn:function({ close }){return [_c('nav',{staticClass:"flex flex-col bg-white w-42 rounded box-border"},[_c('c-list-item',{attrs:{"active":!_vm.getParams.created_at_from},on:{"click":function($event){_vm.filterPeriod();
                close();}}},[_vm._v(" За все время ")]),_vm._l((_vm.periodTimeObj),function(Val,Key,idx){return _c('c-list-item',{key:idx,attrs:{"active":_vm.periodTimeKey === Key},on:{"click":function($event){_vm.filterPeriod(Key);
                close();}}},[_vm._v(" "+_vm._s(Val)+" ")])})],2)]}}],null,false,3328414578)})],1)],1),(!_vm.list.length)?_c('center',{staticClass:"h-screen-sm flex"},[_c('h3',{staticClass:"taleggio m-auto"},[_vm._v(" Ни одна новость не удовлетворяет "),_c('br'),_vm._v("условиям фильтра ")])]):_vm._e(),_c('transition-group',{staticClass:"grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8",attrs:{"name":"fade"}},_vm._l((_vm.list),function(It){return _c('RouterLink',{key:It.id,staticClass:"group",attrs:{"to":`/${It.id}`}},[_c('figure',[_c('picture',{staticClass:"overflow-hidden rounded-16 block aspect-10/16"},[_c('div',{staticClass:"object-fill min-h-full min-w-full bg-no-repeat bg-cover bg-center blur-5 filter scale-120 transform",style:({
              backgroundImage: `url(${
                It.images[0] ||
                'https://raw.githubusercontent.com/zupra/test/f00bc426b0cb7c28aba1460ee1178d9bbc4f5e9e/img/basic.svg'
              })`,
            })}),_c('div',{staticClass:"object-fill min-h-full min-w-full bg-no-repeat bg-contain bg-center z-1 absolute inset-0",style:({
              backgroundImage: `url(${
                It.images[0] ||
                'https://raw.githubusercontent.com/zupra/test/f00bc426b0cb7c28aba1460ee1178d9bbc4f5e9e/img/basic.svg'
              })`,
            })})]),_c('figcaption',[_c('h3',{staticClass:"line-clamp-3 taleggio mt-4 mb-2 group-hover:text-interstellar",domProps:{"innerHTML":_vm._s(It.teaser || It.title)}}),_c('div',{staticClass:"parmigiano opacity-48 group-hover:opacity-64"},[_vm._v(" "+_vm._s(_vm.$dayjs(It.created_at).format("D MMMM YYYY"))+" ")])])])])}),1),(_vm.hasLoadMore)?_c('button',{staticClass:"button mt-10 w-full",on:{"click":_vm.loadMore}},[_vm._v(" Показать ещё ")]):_vm._e()],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }