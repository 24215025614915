<template>
  <span :class="['tag', { tag__active: active }]">{{ title }}</span>
</template>

<script>
export default {
  props: ['title', 'active']
};
</script>

<style lang="scss" scoped>
.tag {
  display: inline-block;
  position: relative;
  padding: 4px 12px 4px 24px;
  color: #fff;
  background-color: #81abee;
  white-space: nowrap;
  font-size: 13px;
  line-height: 16px;
  font-weight: 400;
  border-radius: 16px;
  cursor: pointer;
  &:before {
    content: '';
    position: absolute;
    left: 12px;
    top: calc(50% - 4px);
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #fff;
    opacity: 0.2;
  }
  &__active {
    background-color: #3d75e4 !important;
    &:before {
      opacity: 1;
    }
  }
}
</style>
