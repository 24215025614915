<template functional>
  <!-- :class="props.active && 'active'" -->
  <!-- :class="{active: active}" -->
  <div
    class="TagBtn"
    :class="{active: props.active}"
    v-on="listeners"
  >
    <slot>TEXT</slot>
  </div>
</template>

<script>
// https://stackoverflow.com/questions/50288996/how-to-emit-an-event-from-vue-js-functional-component
export default {
  props: {
    active: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style>.TagBtn {
  --tw-bg-opacity: 1;
  background-color: rgba(129, 171, 238, var(--tw-bg-opacity));
  border-radius: 9999px;
  cursor: pointer;
  font-weight: 500;
  font-size: 13px;
  line-height: 1;
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -ms-flex-negative: 0;
  -webkit-flex-shrink: 0;
  flex-shrink: 0;
  height: 1.5rem;
  padding-left: 0.5rem;
  padding-right: 0.75rem;
  -webkit-column-gap: 0.25rem;
  -moz-column-gap: 0.25rem;
  grid-column-gap: 0.25rem;
  column-gap: 0.25rem;
}
.TagBtn::before {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  border-radius: 9999px;
  -ms-flex-negative: 0;
  -webkit-flex-shrink: 0;
  flex-shrink: 0;
  height: 0.5rem;
  opacity: 0.16;
  content: '';
  width: 0.5rem;
}
.TagBtn.active {
  --tw-bg-opacity: 1;
  background-color: rgba(61, 117, 228, var(--tw-bg-opacity));
}
.TagBtn.active::before {
  opacity: 1;
}</style>
